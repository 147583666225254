import React, { useState, useRef, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import SecondaryLanding from "../../components/layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import Icon from "../../components/custom-widgets/icon";

import filterCdRecords from "../../helpers/filterCdRecords";
import buildCdRow from "../../helpers/buildCdRow";
import getProductDataByName from "../../helpers/getProductDataByName";

import styles from "./interest-rates.module.scss";

const InterestRates = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiCdS {
        nodes {
          APY
          CostTier
          InterestRate
          ProductType
          RenewalTerm
          Term
          WithEligibleAccount
          id
        }
      }
      allStrapiProducts {
        nodes {
          Name
          ProductData {
            name
            value
          }
        }
      }
      miscValueDates: allStrapiMiscValues(filter: { Name: { eq: "Bank Rates - Date" } }) {
        nodes {
          Value
        }
      }
    }
  `);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  const ratesDate = data.miscValueDates.nodes[0].Value;

  const cds = data.allStrapiCdS;

  function getProductValue(productName, productDataName) {
    return getProductDataByName(data.allStrapiProducts, productName, productDataName)?.value ?? "N/A";
  }

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Personal Rates"
    }
  ];

  const title = "Bank Interest Rates - CD, Money Market, Savings Rates";
  const SEOData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Learn about WaFd Bank's savings rates including our bank interest rates on cd's, money market accounts, savings interest rates and more. Find the best bank rate for your nest egg."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/interest-rates"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const productsData = [
    {
      title: "4-5 Month CD Special",
      href: "#four-to-five-month-cd-special",
      id: "four-to-five-month-cd-special-link"
    },
    {
      title: "Start Savings",
      href: "#start-savings",
      id: "start-savings-link"
    },
    {
      title: "Interest Checking",
      href: "#interest-checking",
      id: "interest-checking-link"
    },
    {
      title: "7 Month CD Special",
      href: "#seven-month-cd-special",
      id: "seven-month-cd-special-link"
    },
    {
      title: "Savings",
      href: "#savings",
      id: "savings-link"
    },
    {
      title: "Fixed Rate Certificates of Deposit",
      href: "#fixed-rate-cd",
      id: "fixed-rate-cd-link"
    },
    {
      title: "13 Month CD Special",
      href: "#thirteen-month-cd-special",
      id: "thirteen-month-cd-special-link"
    },
    {
      title: "Minor Savings",
      href: "#minor-savings",
      id: "minor-savings-link"
    },
    {
      title: "Download Rate Sheet",
      href: "/documents/rates-consumer-business-website.pdf",
      ratesheet: true,
      id: "interest-rates-pdf"
    },
    {
      title: "19 Month CD Special",
      href: "#nineteen-month-cd-special",
      id: "nineteen-month-cd-special-link"
    },
    {
      title: "Money Market",
      href: "#money-market",
      id: "money-market-link"
    },
    {
      title: "Download California Rate Sheet",
      href: "/documents/wafd-bank-california-consumer-business-rate-sheet.pdf",
      ratesheet: true,
      id: "california-interest-rates-pdf"
    },
    {
      title: "High Yield Money Market",
      href: "#high-yield-money-market",
      id: "high-yield-money-market-link",
      colClass: "mb-0"
    },
    {
      title: "Gold Money Market",
      href: "#gold-money-market",
      id: "gold-money-market-link",
      colClass: "mb-0"
    }
  ];

  // Scroll Functionality
  const scrollToTop = () => {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  const backToTopRef = useRef();
  // Scroll Position
  useEffect(() => {
    backToTopRef.current.style.bottom = showBackToTopButton ? "0px" : "-60px";
  }, [showBackToTopButton]);

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", function () {
      window.pageYOffset > 400 ? setShowBackToTopButton(true) : setShowBackToTopButton(false);
    });
  }

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container adjust-padding-bottom">
        <h1>WaFd Bank Interest Rates</h1>
        <p className="text-xs text-secondary">
          Effective <span id="interest-rates-effective-date">{ratesDate}</span> and subject to change without notice.
          Terms on amounts over $1 million must be approved in advance. Member FDIC. Account service charges or
          withdrawals will reduce earnings. Please ask us for details.
        </p>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row no-gutter">
            {productsData.map((product, index) => (
              <div className={`${product.colClass ? product.colClass : "mb-3"} col-sm-6 col-md-4`} key={index}>
                {product.ratesheet ? (
                  <a
                    id={product.id}
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-70 d-flex text-decoration-none"
                    href={product.href}
                  >
                    <Icon name="file-pdf" class="mr-2 mt-1" />
                    {product.title}
                  </a>
                ) : (
                  product.title && (
                    <a id={product.id} className="text-gray-70 d-flex text-decoration-none" href={product.href}>
                      <Icon name="arrow-down" class="mr-2 mt-1" />
                      {product.title}
                    </a>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="container adjust-padding-bottom">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
          {/* 4-5 Month CD Special */}
          <div className="col mb-4 card border-0" id="four-to-five-month-cd-special">
            <div className="bg-light card-body border-radius-top-12">
              <h2>
                <span className="text-success">4-5 Month CD Special</span>*
              </h2>
              <div className="font-weight-bold mb-4">$1,000 - $1,000,000</div>
              <div className="mb-4" id="4-5-month-penalty">
                A penalty may be assessed for early withdrawal.
              </div>
              <div className="mb-4" id="4-5-month-with-eligible-checking">
                <div className="font-weight-bold">
                  With Eligible<sup>&dagger;</sup> Checking or $25k New Money:
                </div>
                <div>Interest Rate/Annual Percentage Yield</div>
                <div>
                  <span id="4-5-month-cd-with-eligible-checking-interest-rate">
                    {getProductValue("4-5-Month CD Special", "Interest Rate - Eligible Checking")}
                  </span>
                  /
                  <span id="4-5-month-cd-with-eligible-checking-apy">
                    {getProductValue("4-5-Month CD Special", "APY - Eligible Checking")}
                  </span>
                </div>
              </div>
              <div className="mb-4" id="4-5-month-with-out-eligible-checking">
                <div className="font-weight-bold">
                  Without Eligible<sup>&dagger;</sup> Checking:
                </div>
                <div>Not Available</div>
              </div>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/locations" className="btn btn-primary btn-block mb-1" id="4-5-month-cd-special-cta">
                Open in Branch
              </Link>
            </div>
          </div>
          {/* 7-Month CD Special */}
          <div className="col mb-4 card border-0" id="seven-month-cd-special">
            <div className="bg-light card-body border-radius-top-12">
              <h2>
                <span className="text-success">7 Month CD Special</span>*
              </h2>
              <div className="font-weight-bold mb-4">$1,000 - $1,000,000</div>
              <div className="mb-4" id="7-month-penalty">
                A penalty may be assessed for early withdrawal.
              </div>
              <div className="mb-4" id="7-month-with-eligible-checking">
                <div className="font-weight-bold">
                  With Eligible<sup>&dagger;</sup> Checking or $25k New Money:
                </div>
                <div>Interest Rate/Annual Percentage Yield</div>
                <div>
                  <span id="7-month-cd-with-eligible-checking-interest-rate">
                    {getProductValue("7-Month CD Special", "Interest Rate - Eligible Checking")}
                  </span>
                  /
                  <span id="7-month-cd-with-eligible-checking-apy">
                    {getProductValue("7-Month CD Special", "APY - Eligible Checking")}
                  </span>
                </div>
              </div>
              <div className="mb-4" id="7-month-with-out-eligible-checking">
                <div className="font-weight-bold">
                  Without Eligible<sup>&dagger;</sup> Checking:
                </div>
                <div>Interest Rate/Annual Percentage Yield</div>
                <div>
                  <span id="7-month-cd-without-eligible-checking-interest-rate">
                    {getProductValue("7-Month CD Special", "Interest Rate - Not Eligible Checking")}
                  </span>
                  /
                  <span id="7-month-cd-without-eligible-checking-apy">
                    {getProductValue("7-Month CD Special", "APY - Not Eligible Checking")}
                  </span>
                </div>
              </div>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="7-month-cd-special-cta">
                Open an Account
              </Link>
            </div>
          </div>
          {/* 13-Month CD Special */}
          <div className="col mb-4 card border-0" id="thirteen-month-cd-special">
            <div className="bg-light card-body border-radius-top-12">
              <h2>
                <span className="text-success">13 Month CD Special</span>*
              </h2>
              <div className="font-weight-bold mb-4">$1,000 - $1,000,000</div>
              <div className="mb-4" id="13-month-penalty">
                A penalty may be assessed for early withdrawal.
              </div>
              <div className="mb-4" id="13-month-with-eligible-checking">
                <div className="font-weight-bold">
                  With Eligible<sup>&dagger;</sup> Checking or $25k New Money:
                </div>
                <div>Interest Rate/Annual Percentage Yield</div>
                <div>
                  <span id="13-month-cd-with-eligible-checking-interest-rate">
                    {getProductValue("13-Month CD Special", "Interest Rate - Eligible Checking")}
                  </span>
                  /
                  <span id="13-month-cd-with-eligible-checking-apy">
                    {getProductValue("13-Month CD Special", "APY - Eligible Checking")}
                  </span>
                </div>
              </div>
              <div className="mb-4" id="13-month-with-out-eligible-checking">
                <div className="font-weight-bold">
                  Without Eligible<sup>&dagger;</sup> Checking:
                </div>
                <div>Interest Rate/Annual Percentage Yield</div>
                <div>
                  <span id="13-month-cd-without-eligible-checking-interest-rate">
                    {getProductValue("13-Month CD Special", "Interest Rate - Not Eligible Checking")}
                  </span>
                  /
                  <span id="13-month-cd-without-eligible-checking-apy">
                    {getProductValue("13-Month CD Special", "APY - Not Eligible Checking")}
                  </span>
                </div>
              </div>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="13-month-cd-special-cta">
                Open an Account
              </Link>
            </div>
          </div>
          {/* 19-Month CD Special */}
          <div className="col mb-4 card border-0" id="nineteen-month-cd-special">
            <div className="bg-light card-body border-radius-top-12">
              <h2>
                <span className="text-success">19 Month CD Special</span>*
              </h2>
              <div className="font-weight-bold mb-4">$1,000 - $1,000,000</div>
              <div className="mb-4" id="19-month-penalty">
                A penalty may be assessed for early withdrawal.
              </div>

              <div className="mb-4" id="19-month-with-eligible-checking">
                <div className="font-weight-bold">
                  With Eligible<sup>&dagger;</sup> Checking or $25k New Money:
                </div>
                <div>Interest Rate/Annual Percentage Yield</div>
                <div>
                  <span id="19-month-cd-with-eligible-checking-interest-rate">
                    {getProductValue("19-Month CD Special", "Interest Rate - Eligible Checking")}
                  </span>
                  /
                  <span id="19-month-cd-with-eligible-checking-apy">
                    {getProductValue("19-Month CD Special", "APY - Eligible Checking")}
                  </span>
                </div>
              </div>
              <div className="mb-4" id="19-month-with-out-eligible-checking">
                <div className="font-weight-bold">
                  Without Eligible<sup>&dagger;</sup> Checking:
                </div>
                <div>Interest Rate/Annual Percentage Yield</div>
                <div>
                  <span id="19-month-cd-without-eligible-checking-interest-rate">
                    {getProductValue("19-Month CD Special", "Interest Rate - Not Eligible Checking")}
                  </span>
                  /
                  <span id="19-month-cd-without-eligible-checking-apy">
                    {getProductValue("19-Month CD Special", "APY - Not Eligible Checking")}
                  </span>
                </div>
              </div>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="9-month-cd-special-cta">
                Open an Account
              </Link>
            </div>
          </div>
          {/* High Yield Money Market */}
          <div className="col mb-4 card border-0" id="high-yield-money-market">
            <div className="bg-light card-body border-radius-top-12">
              <h2>
                <span className="text-success">High Yield Money Market</span>**
              </h2>
              <ul>
                <li className="font-weight-bold mb-0" id="high-yield-money-market-minimum-balance-to-open">
                  {getProductValue("High Yield Money Market", "Minimum Balance to Open")} minimum to open
                </li>
                <li id="high-yield-market-description">{getProductValue("High Yield Money Market", "Description")}</li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="high-yield-money-market-tier-1-price-range">
                  {getProductValue("High Yield Money Market", "Tier 1 Price Range")}
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-1-interest-rate">
                  {getProductValue("High Yield Money Market", "Tier 1 Interest Rate")} Interest Rate
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-1-apy">
                  {getProductValue("High Yield Money Market", "Tier 1 APY")} Annual Percentage Yield
                </li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="high-yield-money-market-tier-3-price-range">
                  {getProductValue("High Yield Money Market", "Tier 2 Price Range")}
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-2-interest-rate">
                  {getProductValue("High Yield Money Market", "Tier 2 Interest Rate")} Interest Rate
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-2-apy">
                  {getProductValue("High Yield Money Market", "Tier 2 APY")} Annual Percentage Yield
                </li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="high-yield-money-market-tier-3-price-range">
                  {getProductValue("High Yield Money Market", "Tier 3 Price Range")}
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-3-interest-rate">
                  {getProductValue("High Yield Money Market", "Tier 3 Interest Rate")} Interest Rate
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-3-apy">
                  {getProductValue("High Yield Money Market", "Tier 3 APY")} Annual Percentage Yield
                </li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="high-yield-money-market-tier-4-price-range">
                  {getProductValue("High Yield Money Market", "Tier 4 Price Range")}
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-4-interest-rate">
                  {getProductValue("High Yield Money Market", "Tier 4 Interest Rate")} Interest Rate
                </li>
                <li className="mb-0" id="high-yield-money-market-tier-4-apy">
                  {getProductValue("High Yield Money Market", "Tier 4 APY")} Annual Percentage Yield
                </li>
              </ul>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="high-yield-money-market-cta">
                Open an Account
              </Link>
            </div>
          </div>
          {/* Start Savings */}
          <div className="col mb-4 card border-0" id="start-savings">
            <div className="bg-light card-body border-radius-top-12">
              <h2 className="text-success">Start Savings</h2>
              <ul id="start-savings-list">
                <li className="mb-0" id="start-savings-list-item-1">
                  <strong>{getProductValue("Start Savings", "Initial Deposit")} minimum to open</strong>
                </li>
                <li className="mb-0" id="start-savings-list-item-2">
                  No minimum to earn blended rate interest
                </li>
                <li className="mb-0" id="start-savings-list-item-3">
                  No monthly service charge
                </li>
                <li className="mb-0" id="start-savings-list-item-4">
                  Checking account required to open
                </li>
              </ul>
              <ul className="list-unstyled" id="start-savings-list-2">
                <li className="mb-0" id="start-savings-list-2-item-1">
                  <strong>$0 - $500</strong>
                </li>
                <li className="mb-0" id="start-savings-list-2-item-2">
                  {getProductValue("Start Savings", "Tier 1 Interest Rate")}% Interest Rate
                </li>
                <li className="mb-0" id="start-savings-list-2-item-3">
                  {getProductValue("Start Savings", "Tier 1 APY")}% Annual Percentage Yield
                </li>
              </ul>
              <ul className="list-unstyled" id="start-savings-list-3">
                <li className="mb-0" id="start-savings-list-3-item-1">
                  <strong>$500 - $1000</strong>
                </li>
                <li className="mb-0" id="start-savings-list-3-item-2">
                  {getProductValue("Start Savings", "Tier 2 Interest Rate")}% Interest Rate
                </li>
                <li className="mb-0" id="start-savings-list-3-item-3">
                  {getProductValue("Start Savings", "Tier 2 APY")}% Annual Percentage Yield
                </li>
              </ul>
              <ul className="list-unstyled" id="start-savings-list-4">
                <li className="mb-0" id="start-savings-list-4-item-1">
                  <strong>$1000 +</strong>
                </li>
                <li className="mb-0" id="start-savings-list-4-item-2">
                  {getProductValue("Start Savings", "Tier 3 Interest Rate")}% Interest Rate
                </li>
                <li className="mb-0" id="start-savings-list-4-item-3">
                  {getProductValue("Start Savings", "Tier 3 APY")}% Annual Percentage Yield
                </li>
              </ul>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="start-savings-cta">
                Open an Account
              </Link>
            </div>
          </div>
          {/* Savings */}
          <div className="col mb-4 card border-0" id="savings">
            <div className="bg-light card-body border-radius-top-12">
              <h2 className="text-success">Savings</h2>
              <ul>
                <li className="font-weight-bold mb-0" id="savings-minimum-balance-to-open">
                  {getProductValue("Savings", "Minimum Balance to Open")} minimum to open
                </li>
                <li className="mb-0" id="savings-minimum-to-earn-interest">
                  {getProductValue("Savings", "Minimum Balance to Earn Interest")} minimum to earn interest
                </li>
                <li className="mb-0" id="savings-minimum-balance-to-waive-fee">
                  {getProductValue("Savings", "Minimum Balance to Waive Fee")} minimum balance to waive{" "}
                  {getProductValue("Savings", "Minimum Balance Fee")} monthly service charge
                </li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="savings-price-range">
                  $100 Plus
                </li>
                <li className="mb-0" id="savings-interest-rate">
                  {getProductValue("Savings", "Interest Rate")} Interest Rate
                </li>
                <li className="mb-0" id="savings-apy">
                  {getProductValue("Savings", "APY")} Annual Percentage Yield
                </li>
              </ul>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="savings-cta">
                Open an Account
              </Link>
            </div>
          </div>
          {/* Minor Savings */}
          <div className="col mb-4 card border-0" id="minor-savings">
            <div className="bg-light card-body border-radius-top-12">
              <h2 className="text-success">Minor Savings</h2>
              <ul>
                <li className="font-weight-bold mb-0" id="minor-savings-minimum-balance-to-open">
                  {getProductValue("Minor Savings", "Minimum Balance to Open")} minimum to open and earn interest
                </li>
                <li id="minor-savings-monthly-service-fee">No monthly service charge.</li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="minor-savings-tier-1-price-range">
                  {getProductValue("Minor Savings", "Tier 1 Price Range")}
                </li>
                <li className="mb-0" id="minor-savings-tier-1-interest-rate">
                  {getProductValue("Minor Savings", "Tier 1 Interest Rate")} Interest Rate
                </li>
                <li className="mb-0" id="minor-savings-tier-1-apy">
                  {getProductValue("Minor Savings", "Tier 1 APY")} Annual Percentage Yield
                </li>
              </ul>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link
                to="/personal-banking/savings-account/youth-savings-account"
                className="btn btn-primary btn-block mb-1"
                id="minor-savings-cta"
              >
                Open an Account
              </Link>
            </div>
          </div>
          {/* Money Market */}
          <div className="col mb-4 card border-0" id="money-market">
            <div className="bg-light card-body border-radius-top-12">
              <h2 className="text-success">Money Market</h2>
              <ul>
                <li className="font-weight-bold mb-0" id="money-market-minimum-balance-to-open">
                  {getProductValue("Money Market", "Minimum Balance to Open")} minimum to open
                </li>
                <li id="money-market-description">{getProductValue("Money Market", "Description")}</li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="money-market-tier-1-price-range">
                  {getProductValue("Money Market", "Tier 1 Price Range")}
                </li>
                <li className="mb-0" id="money-market-tier-1-interest-rate">
                  {getProductValue("Money Market", "Tier 1 Interest Rate")} Interest Rate
                </li>
                <li className="mb-0" id="money-market-tier-1-apy">
                  {getProductValue("Money Market", "Tier 1 APY")} Annual Percentage Yield
                </li>
              </ul>
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="money-market-tier-2-price-range">
                  {getProductValue("Money Market", "Tier 2 Price Range")}
                </li>
                <li className="mb-0" id="money-market-tier-2-interest-rate">
                  {getProductValue("Money Market", "Tier 2 Interest Rate")} Interest Rate
                </li>
                <li className="mb-0" id="money-market-tier-2-apy">
                  {getProductValue("Money Market", "Tier 2 APY")} Annual Percentage Yield
                </li>
              </ul>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="money-market-cta">
                Open an Account
              </Link>
            </div>
          </div>
          {/* Gold Money Market */}
          <div className="col mb-4 card border-0" id="gold-money-market">
            <div className="bg-light card-body border-radius-top-12">
              <h2>
                <span className="text-success">Gold Money Market</span>***
              </h2>
              <ul>
                <li className="font-weight-bold mb-0" id="gold-money-market-minimum-balance-to-open">
                  {getProductValue("Gold Money Market", "Minimum Balance to Open")} minimum to open
                </li>
                <li id="gold-market-description">{getProductValue("Gold Money Market", "Description")}</li>
              </ul>
              <div className="font-weight-bold" id="gold-money-market-tier-1-range">
                {getProductValue("Gold Money Market", "Tier 1 Price Range")}
              </div>
              <div className="mb-4">
                Interest Rate/Annual Percentage Yield
                <br />
                <span id="gold-money-market-tier-1-interest-rate">
                  {getProductValue("Gold Money Market", "Tier 1 Interest Rate")}
                </span>
                /<span id="gold-money-market-tier-1-apy">{getProductValue("Gold Money Market", "Tier 1 APY")}</span>
              </div>
              <div id="gold-money-market-tier-2-range" className="font-weight-bold">
                {getProductValue("Gold Money Market", "Tier 2 Price Range")}
              </div>
              <div className="mb-4">
                Interest Rate/Annual Percentage Yield
                <br />
                <span id="gold-money-market-tier-2-interest-rate">
                  {getProductValue("Gold Money Market", "Tier 2 Interest Rate")}
                </span>
                /<span id="gold-money-market-tier-2-apy">{getProductValue("Gold Money Market", "Tier 2 APY")}</span>
              </div>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="gold-money-market-cta">
                Open an Account
              </Link>
            </div>
          </div>
          {/* Interest Checking */}
          <div className="col mb-4 card border-0 h-100" id="interest-checking">
            <div className="bg-light card-body border-radius-top-12">
              <h2 className="text-success">Interest Checking</h2>
              <ul>
                <li className="font-weight-bold mb-0" id="interest-checking-minimum-balance-to-open">
                  {getProductValue("Interest Checking", "Initial Deposit")} minimum to open
                </li>
                <li id="interest-checking-description">
                  Avoid monthly service charge if you maintain a{" "}
                  {getProductValue("Interest Checking", "Minimum Balance to Waive Service Charge")}. If not, a charge of{" "}
                  {getProductValue("Interest Checking", "Service Charge")} will apply.
                </li>
              </ul>
              {/* Tier 1 Price range and Interest Rate / APY */}
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="interest-checking-tier-1-price-range">
                  {getProductValue("Interest Checking", "Tier 1 Price Range")}
                </li>
                <li className="mb-0" id="interest-checking-tier-1-interest-rate">
                  {getProductValue("Interest Checking", "Tier 1 Interest Rate")} Interest Rate
                </li>
                <li className="mb-0" id="interest-checking-tier-1-apy">
                  {getProductValue("Interest Checking", "Tier 1 APY")} Annual Percentage Yield
                </li>
              </ul>
              {/* Tier 2 Price range and Interest Rate / APY */}
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="interest-checking-tier-2-price-range">
                  {getProductValue("Interest Checking", "Tier 2 Price Range")}
                </li>
                <li className="mb-0" id="interest-checking-tier-2-interest-rate">
                  {getProductValue("Interest Checking", "Tier 2 Interest Rate")} Interest Rate
                </li>
                <li className="mb-0" id="interest-checking-tier-2-apy">
                  {getProductValue("Interest Checking", "Tier 2 APY")} Annual Percentage Yield
                </li>
              </ul>
              {/* Tier 3 Price range and Interest Rate / APY */}
              <ul className="list-unstyled">
                <li className="font-weight-bold mb-0" id="interest-checking-tier-3-price-range">
                  {getProductValue("Interest Checking", "Tier 3 Price Range")}
                </li>
                <li className="mb-0" id="interest-checking-tier-3-interest-rate">
                  {getProductValue("Interest Checking", "Tier 3 Interest Rate")} Interest Rate
                </li>
                <li className="mb-0" id="interest-checking-tier-3-apy">
                  {getProductValue("Interest Checking", "Tier 3 APY")} Annual Percentage Yield
                </li>
              </ul>
            </div>
            <div className="card-footer border-radius-bottom-12 border-0 bg-light pt-0 pb-3">
              <Link to="/open-an-account" className="btn btn-primary btn-block mb-1" id="interest-checking-cta">
                Open an Account
              </Link>
            </div>
          </div>
          {/* Fixed Rate Certificates of Deposit */}
          <div className="col col-lg-8 mb-4 card border-0" id="fixed-rate-cd">
            <div className="bg-light card-body border-radius-12">
              <h2 className="text-success">Fixed Rate Certificates of Deposit</h2>
              <div id="min-balance-disclaimer" className="font-weight-bold">
                $1,000 minimum to open ($500 minimum for minors).
              </div>
              <div id="withdrawal-disclaimer" className="mb-4">
                A penalty may be assessed for early withdrawal.
              </div>
              <Link to="/locations" className="btn btn-primary" id="find-a-branch-cta">
                Open in Branch
              </Link>
              <div className="table-responsive mt-4">
                <table className="table table-bordered text-xs-xs mb-0">
                  <thead>
                    <tr>
                      <th>Choice of Terms</th>
                      <th>
                        <span className="text-nowrap">$1,000 +</span>{" "}
                        <span className="text-nowrap">Interest Rate/APY %</span>
                      </th>
                      <th>
                        <span className="text-nowrap">$90,000 +</span>{" "}
                        <span className="text-nowrap">Interest Rate/APY %</span>
                      </th>
                      <th>Renewal Term</th>
                    </tr>
                  </thead>
                  <tbody>
                    {buildCdRow(filterCdRecords(cds, "14 Days - 2 Months", "14 Days - 2 Months"))}
                    {buildCdRow(filterCdRecords(cds, "3 Months", "3 Months"))}
                    {/* {buildCdRow(filterCdRecords(cds, "4 - 5 Months", "4 - 5 Months"))} */}
                    <tr>
                      <td id="4-5months-term">4 - 5 Months</td>
                      <td id="4-5months-interest-rate-low-tier">See above</td>
                      <td id="4-5months-interest-rate-high-tier">Listed CD</td>
                      <td id="4-5months-renewal-term">Specials</td>
                    </tr>
                    {buildCdRow(filterCdRecords(cds, "6 - 11 Months", "6 - 11 Months"))}
                    {buildCdRow(filterCdRecords(cds, "12 - 17 Months", "12 - 17 Months"))}
                    {buildCdRow(filterCdRecords(cds, "18 - 23 Months", "18 - 23 Months"))}
                    {buildCdRow(filterCdRecords(cds, "24 - 35 Months", "24 - 35 Months"))}
                    {buildCdRow(filterCdRecords(cds, "36 - 47 Months", "36 - 47 Months"))}
                    {buildCdRow(filterCdRecords(cds, "48 - 59 Months", "48 - 59 Months"))}
                    {buildCdRow(filterCdRecords(cds, "60 Months", "60 Months"))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="text-secondary text-xs">
          <p id="eligible-business-checking-footnote" className="mb-2">
            <sup>&dagger;</sup> Eligible checking accounts include Free, Rewards, Premium Rewards, or Interest Checking.
          </p>
          <p id="cd-special-min-balance-footnote" className="mb-2">* $1,000 minimum to open ($500 minimum to open for minors)</p>
          <p id="high-yield-money-market-footnote" className="mb-2">
            ** Not available for IRAs or Business. Promotion may be discontinued at any time
          </p>
          <p id="premium-money-market-min-balance-footnote">*** Not available for IRAs.</p>
        </div>
      </section>
      <div
        ref={backToTopRef}
        id="back-to-top"
        role="button"
        onClick={(e) => scrollToTop()}
        onKeyDown={(e) => scrollToTop()}
        className={`${styles.backToTop} mb-0 bg-primary text-white py-2 px-3 border-radius-top-left-6`}
      >
        <Icon name="arrow-to-top" class="fa-lg" />
      </div>
    </SecondaryLanding>
  );
};

export default InterestRates;

